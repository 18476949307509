// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import noop from 'lodash/noop';
import { FC } from 'react';
import { copy } from '~/common/copy/copy';
import { Item } from '~/common/gql';
import { ActionType } from '~/common/utilities/usage-event';
import { useItemUpdateMutationHelper } from '~/hooks/useItemUpdateMutationHelper';
import { useToastHelper } from '~/hooks/useToastHelper';
import { useUsageEventCreateMutationHelper } from '~/hooks/useUsageEventCreateMutationHelper';

interface Props extends Omit<ModalProps, 'children'> {
  item: Pick<Item, 'id'>;
}

export const ModalItemNoteDelete: FC<Props> = ({
  item,
  isOpen,
  onClose,
  ...modalProps
}) => {
  const { renderToastWith } = useToastHelper();
  const { logUsageEvent } = useUsageEventCreateMutationHelper();
  const { editNote } = useItemUpdateMutationHelper();

  const onDelete = () => {
    if (!item.id) return;
    editNote({
      for: { id: item.id },
      note: undefined,
      onSuccess: renderToastWith({
        message: copy.deleteNoteSuccess,
        then: onClose,
      }),
      onError: renderToastWith({
        message: copy.deleteNoteError,
        then: noop,
      }),
    });

    logUsageEvent({
      actionType: ActionType.DELETED_NOTE_IN_APP,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs" {...modalProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Delete note</ModalHeader>
        <ModalBody>Are you sure you want to delete this note?</ModalBody>
        <ModalFooter gap={2}>
          <Button variant="npOutline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="outline"
            borderColor="red"
            color="red"
            onClick={onDelete}
          >
            Yes, delete note
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
