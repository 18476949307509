// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Heading, Link } from '@chakra-ui/react';
import { FC } from 'react';
import { copy } from '~/common/copy/copy';

interface Props {
  onClick: VoidFunction;
  isDone: boolean;
}

export const TodoUpdatedToast: FC<Props> = ({ onClick, isDone }) => {
  const successMessage = isDone
    ? copy.todoMarkedCompleteSucess
    : copy.todoMarkedIncompleteSucess;
  return (
    <Heading fontSize="md" as="b">
      {successMessage}
      {isDone && (
        <Link
          ml={4}
          color="npBlue.300"
          _hover={{ color: 'npBlue.400' }}
          onClick={onClick}
        >
          Undo
        </Link>
      )}
    </Heading>
  );
};
