// Copyright © 2022 Niphtio, Inc.
// All Rights Reserved.

import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { PanelPaddedBox } from '@niphtio/np-theme';
import { FC } from 'react';
import { CollectionPicker } from '~/containers/common/Collection/CollectionPicker/CollectionPicker';
import { CollectionPickerInput } from '~/containers/common/Collection/CollectionPicker/CollectionPickerInput';
import { ModalCollectionPickerProps } from './ModalCollectionPickerProps';

export const ModalCollectionPicker: FC<ModalCollectionPickerProps> = ({
  popup,
  picker,
}) => {
  return (
    <Modal
      onClose={popup.onClose}
      isOpen={popup.isOpen}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent
        m={[4, 4, 16]}
        maxHeight={[
          'calc(100% - 2rem)',
          'calc(100% - 2rem)',
          'calc(100% - 7.5rem)',
        ]}
      >
        <ModalHeader>Edit collection</ModalHeader>
        <ModalCloseButton />
        <ModalHeader p={0}>
          <PanelPaddedBox>
            <CollectionPickerInput
              value={picker.searchTerm}
              onChange={(e) => picker.onSearchTermChanged(e.target.value)}
              autoFocus
            />
          </PanelPaddedBox>
        </ModalHeader>
        <Divider />
        <ModalBody p={0} maxH="md">
          <CollectionPicker tagPickerProps={picker} />
        </ModalBody>
        <Divider />
        <ModalFooter p={0}>
          <PanelPaddedBox justifyContent="flex-end">
            <Button variant="npPrimary" onClick={popup.onClose}>
              Done
            </Button>
          </PanelPaddedBox>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
