// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, Textarea } from '@chakra-ui/react';
import { TextareaResponsiveProps } from './TextareaResponsive';

/**
 * Allows responsive rows based only on the value of the input
 */
export const TextareaResponsiveToInput = forwardRef<
  TextareaResponsiveProps,
  'textarea'
>(({ rows, ...props }, ref) => {
  const autoSizeHeight = (e) => {
    e.currentTarget.style.height = '';
    e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px';
  };

  return (
    <Textarea
      onInput={autoSizeHeight}
      onFocus={autoSizeHeight}
      maxH={'sm'}
      ref={ref}
      {...props}
    />
  );
});
