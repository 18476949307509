// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import {
  forwardRef,
  ResponsiveValue,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';

export interface TextareaResponsiveProps extends Omit<TextareaProps, 'rows'> {
  rows: ResponsiveValue<TextareaProps['rows']>;
}

/**
 * Allows responsive rows.
 */
export const TextareaResponsive = forwardRef<
  TextareaResponsiveProps,
  'textarea'
>(({ rows, ...props }, ref) => {
  const autoSizeHeight = (e) => {
    e.currentTarget.style.height = '';
    e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px';
  };

  return (
    <Textarea
      onInput={autoSizeHeight}
      onFocus={autoSizeHeight}
      maxH={'sm'}
      ref={ref}
      {...props}
    />
  );
});
