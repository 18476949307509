// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { Modal, ModalOverlay, ModalProps, useBoolean } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { Item } from '~/common/gql';
import { useNotesQuotaQuery } from '~/common/gql/user.generated';
import { getIsNoteCreated } from '~/common/utilities/item-utils/getIsNoteCreated';
import { useRefUpdater } from '~/hooks/useRefUpdater';
import { ModalContentItemNoteCreate } from './ModalContentItemNoteCreate';
import { ModalContentItemNoteEdit } from './ModalContentItemNoteEdit';

interface Props extends Omit<ModalProps, 'children'> {
  item: Pick<Item, 'id' | 'note' | 'urlData' | 'noteCreatedAt'>;
  onDelete: VoidFunction;
}

export const ModalItemNoteEdit: FC<Props> = ({
  item,
  onDelete,
  isOpen,
  onClose: _onClose,
  ...modalProps
}) => {
  const [isCreateNoteButtonPressed, setIsCreateNoteButtonPressed] =
    useBoolean();
  const notesQuotaQuery = useNotesQuotaQuery();
  const notesQuotaQueryRef = useRefUpdater(notesQuotaQuery);
  const isNoteCreated = getIsNoteCreated(item);
  const isNoteUnlimited = notesQuotaQuery.data?.notesQuota.maximum === null;
  const isNoteEditing =
    isNoteCreated || isCreateNoteButtonPressed || isNoteUnlimited;
  const onClose = () => {
    setIsCreateNoteButtonPressed.off();
    _onClose();
  };

  useEffect(() => {
    if (isOpen) {
      // get latest notes quota data when modal opens
      notesQuotaQueryRef.current.refetch();
    }
  }, [isOpen, notesQuotaQueryRef]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs" {...modalProps}>
      <ModalOverlay />
      {!isNoteEditing && (
        <ModalContentItemNoteCreate
          item={item}
          onCreateNote={setIsCreateNoteButtonPressed.on}
        />
      )}
      {isNoteEditing && (
        <ModalContentItemNoteEdit
          item={item}
          onDelete={onDelete}
          onClose={onClose}
        />
      )}
    </Modal>
  );
};
