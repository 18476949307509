// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
} from '@chakra-ui/react';
import { copy } from '~/common/copy/copy';
import { Item } from '~/common/gql';
import { useItemDeleteMutationHelper } from '~/hooks/useItemDeleteMutationHelper';
import { useToastHelper } from '~/hooks/useToastHelper';

interface Props extends Omit<ModalProps, 'children'> {
  item: Pick<Item, 'id'>;
}

export const ModalItemDelete: React.FC<Props> = ({ item, ...modalProps }) => {
  const { renderToastWith } = useToastHelper();
  const [itemDeleteMutation] = useItemDeleteMutationHelper();

  const onDelete = () => {
    itemDeleteMutation(
      {
        input: {
          id: item.id,
        },
      },
      {
        onSuccess: renderToastWith({
          message: copy.deleteItemSuccess,
          then: modalProps.onClose,
        }),
        onError: renderToastWith({
          message: copy.deleteItemError,
        }),
      },
    );
  };

  return (
    <Modal {...modalProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xs"></Heading>
          <Heading size="md">Permanently delete this item?</Heading>All
          collection assigned to this item will be lost.
        </ModalBody>
        <ModalFooter>
          <Stack direction="row">
            <Button onClick={modalProps.onClose}>Cancel</Button>
            <Button colorScheme="red" onClick={onDelete}>
              Delete
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
