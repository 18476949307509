// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Text, TextProps } from '@chakra-ui/react';
import { FC } from 'react';
import { NotesQuotaResult } from '~/common/gql';

interface Props extends TextProps {
  notesQuota: Pick<NotesQuotaResult, 'remaining'>;
}

export const NoteBoxMessageNoteCreateRemaining: FC<Props> = ({
  notesQuota,
  ...props
}) => {
  const noteRemaining = notesQuota?.remaining;
  const isApproachingLimit = noteRemaining <= 2;

  return (
    <Text {...props}>
      You can create{' '}
      <Text as="strong" color={isApproachingLimit ? 'red.500' : undefined}>
        {noteRemaining}
      </Text>{' '}
      more notes this month.
    </Text>
  );
};
