// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import first from 'lodash/first';
import isNil from 'lodash/isNil';
import { TodoItem } from '~/common/gql';
import { EnhancedTodoItem } from './EnhancedTodoItem';

export const getEnhancedTodoItem = (
  todoItems?: TodoItem[],
): EnhancedTodoItem => {
  const todo = first(todoItems);
  const isTodoSelected = !isNil(todo);
  const isTodoMarkedDone = todo && !isNil(todo.markedDoneAt);

  const enhancedTodo: EnhancedTodoItem = {
    id: undefined,
    itemId: undefined,
    creatorId: undefined,
    ...todo,
    isDone: isTodoMarkedDone,
    selected: isTodoSelected,
  };

  return enhancedTodo;
};
