// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Text, TextProps } from '@chakra-ui/react';
import { FC } from 'react';

interface Props extends TextProps {
  maximum: number;
}
export const NoteBoxMessageCreateLimitReached: FC<Props> = ({
  maximum,
  ...props
}) => {
  return (
    <Text {...props}>
      You‘ve reached your limit of <strong>{maximum}</strong> notes/month.
    </Text>
  );
};
