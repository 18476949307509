// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import {
  Box,
  ChakraComponent,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  Switch,
  useColorMode,
} from '@chakra-ui/react';
import { first } from 'lodash';
import { Item, TodoItem } from '~/common/gql';
import { getIsNoteCreated } from '~/common/utilities/item-utils/getIsNoteCreated';
import {
  getIsItemTrashed,
  getIsTodo,
  getIsTodoDone,
} from '~/common/utilities/item-utils/item';
import { useItemTags } from '~/containers/common/Item/useItemTags';
import { TodoUpdatedToast } from '~/containers/common/Todo/TodoUpdatedToast';
import { useItemUpdateMutationHelper } from '~/hooks';
import { useToastHelper } from '~/hooks/useToastHelper';

export interface ItemMenuProps {
  item: Pick<
    Item,
    'id' | 'todo' | 'collection' | 'note' | 'noteCreatedAt' | 'trashedAt'
  >;
  isDarkmodeToggleIncluded?: boolean;
  onEditItemCollections: VoidFunction;
  onRenameTitle: VoidFunction;
  onEditNote: VoidFunction;
  onDeleteNote: VoidFunction;
  onDeleteItem: VoidFunction;
  onTrashItem?: VoidFunction;
}

export const ItemMenu: ChakraComponent<'div', ItemMenuProps> = ({
  item,
  isDarkmodeToggleIncluded,
  onEditItemCollections,
  onRenameTitle,
  onEditNote,
  onDeleteNote,
  onDeleteItem,
  onTrashItem,
  children,
}) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { renderToastWith } = useToastHelper();
  const { trashItem, untrashItem } = useItemUpdateMutationHelper();

  const {
    createTodo,
    removeTodo,
    setTodoDone: _setTodoDone,
  } = useItemTags({
    item: { id: item.id },
  });

  const todo = first(item.todo);
  const isTodo = getIsTodo(item);
  const isDone = getIsTodoDone(item);
  const isTrashed = getIsItemTrashed(item);
  const isNoteCreated = getIsNoteCreated(item);

  const setTodoDone = (todoId: TodoItem['id'], isDone: boolean) => {
    renderToastWith({
      message: (
        <TodoUpdatedToast
          isDone={isDone}
          onClick={() => {
            _setTodoDone(todoId, !isDone);
          }}
        />
      ),
    })();
    _setTodoDone(todoId, isDone);
  };

  return (
    <Menu isLazy={true}>
      {children}
      <MenuList>
        {isDarkmodeToggleIncluded && (
          <>
            <MenuItem display="flex" flex="auto">
              Dark mode
              <Box flex="auto"></Box>
              <Switch
                isChecked={colorMode === 'dark'}
                onChange={toggleColorMode}
              />
            </MenuItem>
            <MenuDivider />
          </>
        )}
        <MenuItem onClick={onRenameTitle}>Rename title</MenuItem>
        <MenuItem onClick={onEditNote}>
          {isNoteCreated ? 'Edit note' : 'Add note'}
        </MenuItem>
        {isNoteCreated && (
          <MenuItem onClick={onDeleteNote}>Delete note</MenuItem>
        )}
        <MenuItem onClick={onEditItemCollections}>Edit collections</MenuItem>
        <MenuItem onClick={isTodo ? () => removeTodo(todo.id) : createTodo}>
          {isTodo ? 'Remove from To do list' : 'Add to To do list'}
        </MenuItem>
        {isTodo && (
          <MenuItem onClick={() => setTodoDone(todo.id, !isDone)}>
            {isDone && <span>Mark complete</span>}
            {!isDone && <span>Mark incomplete</span>}
          </MenuItem>
        )}
        <MenuDivider />
        {!isTrashed && (
          <MenuItem onClick={() => trashItem(item, onTrashItem)}>
            Delete from library
          </MenuItem>
        )}
        {isTrashed && (
          <MenuItem onClick={() => untrashItem(item)}>Restore</MenuItem>
        )}
        {isTrashed && (
          <MenuItem onClick={onDeleteItem}>Permanently delete</MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};
