// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import {
  Button,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MdHistory } from 'react-icons/md';
import { copy } from '~/common/copy/copy';
import { Item } from '~/common/gql';
import { getItemTitle } from '~/common/utilities/item-utils/item';
import { ReactIcon } from '~/components/Icons';
import { TextareaResponsiveToInput } from '~/components/TextareaResponsiveToInput';
import { useItemUpdateMutationHelper } from '~/hooks/useItemUpdateMutationHelper';
import { useToastHelper } from '~/hooks/useToastHelper';

type FormValues = { value: string };

interface Props extends Omit<ModalProps, 'children'> {
  item: Pick<Item, 'id' | 'title' | 'urlData' | 'url'>;
}

export const ModalItemTitleRename: FC<Props> = ({
  item,
  isOpen,
  onClose,
  ...modalProps
}) => {
  const { renderToastWith } = useToastHelper();
  const { editTitle } = useItemUpdateMutationHelper();
  const { register, handleSubmit, formState, getValues, setValue, reset } =
    useForm<FormValues>({
      mode: 'onChange',
      defaultValues: {
        value: getItemTitle(item),
      },
    });

  const {
    errors: { value: valueErrors },
  } = formState;

  // revert is disabled when the current title value !== urlData.title
  // or if the url does not have a title
  const isRevertDisabled =
    !formState.isDirty &&
    (isEmpty(item?.urlData?.title) ||
      item?.urlData?.title == getValues('value'));

  const onResetTitle = () => {
    reset({ value: item?.urlData?.title ?? item.title });
  };

  const handler: SubmitHandler<FormValues> = async ({ value }) => {
    editTitle({
      for: item,
      title: value,
      onSuccess: renderToastWith({
        message: copy.titleEditSuccess,
        then: onClose,
      }),
      onError: renderToastWith({
        message: copy.titleEditError,
      }),
    });
  };

  useEffect(() => {
    // reset the form if item title changes
    if (!isOpen && formState.defaultValues.value !== getItemTitle(item)) {
      reset({ value: getItemTitle(item) });
    }
  }, [formState.defaultValues.value, isOpen, item, reset]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs" {...modalProps}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handler)}>
        <ModalCloseButton />
        <ModalHeader>Rename title</ModalHeader>
        <ModalBody>
          <FormControl>
            <TextareaResponsiveToInput
              rows={0}
              variant="npOutline"
              aria-label="value"
              name="value"
              placeholder="Title"
              autoComplete="off"
              autoFocus
              isRequired
              {...register('value', { required: true })}
            />
            <FormErrorMessage>{valueErrors?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button
            variant="npOutline"
            leftIcon={<ReactIcon as={MdHistory} />}
            onClick={onResetTitle}
            isDisabled={isRevertDisabled}
          >
            Revert
          </Button>
          <Button variant="npPrimary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
