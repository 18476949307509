// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import {
  Button,
  FormControl,
  FormErrorMessage,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalProps,
} from '@chakra-ui/react';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { copy } from '~/common/copy/copy';
import { Item } from '~/common/gql';
import { getIsNoteCreated } from '~/common/utilities/item-utils/getIsNoteCreated';
import { ActionType } from '~/common/utilities/usage-event';
import { TextareaResponsive } from '~/components/TextareaResponsive';
import { useItemUpdateMutationHelper } from '~/hooks/useItemUpdateMutationHelper';
import { useToastHelper } from '~/hooks/useToastHelper';
import { useUsageEventCreateMutationHelper } from '~/hooks/useUsageEventCreateMutationHelper';

type FormValues = { value: string };

interface Props extends Pick<ModalProps, 'onClose'> {
  item: Pick<Item, 'id' | 'note' | 'urlData' | 'noteCreatedAt'>;
  onDelete: VoidFunction;
}

export const ModalContentItemNoteEdit: FC<Props> = ({
  item,
  onDelete,
  onClose,
}) => {
  const isNoteCreated = getIsNoteCreated(item);
  const { renderToastWith } = useToastHelper();
  const { logUsageEvent } = useUsageEventCreateMutationHelper();
  const { editNote } = useItemUpdateMutationHelper();
  const { register, handleSubmit, formState, getValues, setValue, reset } =
    useForm<FormValues>({
      mode: 'onChange',
      defaultValues: {
        value: item?.note,
      },
    });

  const {
    errors: { value: valueErrors },
  } = formState;

  const handler: SubmitHandler<FormValues> = async ({ value }) => {
    if (!item.id) return;
    editNote({
      for: {
        id: item.id,
      },
      note: value,
      onSuccess: renderToastWith({
        message: isNoteCreated ? copy.editNoteSuccess : copy.createNoteSuccess,
        then: onClose,
      }),
      onError: renderToastWith({
        message: isNoteCreated ? copy.editNoteError : copy.createNoteError,
        then: noop,
      }),
    });
  };

  useEffect(() => {
    // reset the form if item note changes
    if (formState.defaultValues.value !== item?.note) {
      reset({ value: item?.note });
    }
  }, [formState.defaultValues.value, item?.note, reset]);

  return (
    <ModalContent as="form" onSubmit={handleSubmit(handler)}>
      <ModalCloseButton />
      <ModalHeader>{isNoteCreated ? 'Edit note' : 'Create note'}</ModalHeader>
      <ModalBody>
        <FormControl>
          <TextareaResponsive
            rows={3}
            variant="npOutline"
            aria-label="value"
            name="value"
            placeholder="Write a note"
            autoComplete="off"
            autoFocus
            isRequired
            {...register('value', { required: true })}
          />
          <FormErrorMessage>{valueErrors?.message}</FormErrorMessage>
        </FormControl>
      </ModalBody>
      <ModalFooter gap={2}>
        <Button
          variant="outline"
          colorScheme="red"
          visibility={!isNil(item?.note) ? 'visible' : 'hidden'}
          onClick={() => {
            onClose();
            onDelete();
          }}
        >
          Delete
        </Button>
        <Button
          variant="npPrimary"
          type="submit"
          onClick={() => {
            const textValue = getValues('value');
            if (textValue) {
              logUsageEvent({
                actionType: ActionType.MODIFY_NOTE_IN_APP,
              });
            }
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
