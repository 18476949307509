// Copyright © 2022 Niphtio, Inc.
// All Rights Reserved.

import { Item, Section } from '~/common/gql';
import { EnhancedCollection } from '~/containers/common/Collection/EnhancedCollection';
import { ModalCollectionPickerProps } from '~/containers/common/Collection/ModalCollectionPicker/ModalCollectionPickerProps';
import { useItemTags } from '~/containers/common/Item/useItemTags';
import { useModalCollectionPicker } from './useModalCollectionPicker';

interface Props {
  item: Pick<Item, 'id'>;
  sections: Section[];
  enhancedCollections: EnhancedCollection[];
}

export const useModalCollectionPickerForItem: (
  props: Props,
) => ModalCollectionPickerProps = ({ item, sections, enhancedCollections }) => {
  const { addTag, removeTag } = useItemTags({
    item: { id: item.id },
  });

  const onTagClick = (collection) => {
    collection.selected ? removeTag(collection.id) : addTag(collection.id);
  };

  const tagPickerPopupProps = useModalCollectionPicker({
    sections,
    enhancedCollections,
    onTagClick,
  });

  return tagPickerPopupProps;
};
