// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import {
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { Item } from '~/common/gql';
import { useNotesQuotaQuery } from '~/common/gql/user.generated';
import { NoteBoxMessageCreateLimitReached } from '../../../../components/NoteBox/NoteBoxMessageCreateLimitReached';
import { NoteBoxMessageNoteCreateRemaining } from '../../../../components/NoteBox/NoteBoxMessageNoteCreateRemaining';

interface Props {
  item: Pick<Item, 'id' | 'note' | 'noteCreatedAt' | 'urlData'>;
  onCreateNote: VoidFunction;
}

export const ModalContentItemNoteCreate: FC<Props> = ({
  item,
  onCreateNote,
}) => {
  const notesQuotaQuery = useNotesQuotaQuery();
  const noteRemaining = notesQuotaQuery.data?.notesQuota?.remaining;
  const noteMaximum = notesQuotaQuery.data?.notesQuota?.maximum;
  const isApproachingLimit = noteRemaining <= 2;
  const isNoteRemainingZero = noteRemaining === 0;
  const isNoteCreateNotAllowed = isNoteRemainingZero;
  const isNoteCreateAllowed = !isNoteRemainingZero;

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader>Create note</ModalHeader>
      <ModalBody>
        {isNoteCreateNotAllowed && (
          <NoteBoxMessageCreateLimitReached maximum={noteMaximum} />
        )}
        {isNoteCreateAllowed && (
          <Flex direction="column" gap={2}>
            <NoteBoxMessageNoteCreateRemaining
              notesQuota={notesQuotaQuery.data?.notesQuota}
            />
            <Button variant="solid" onClick={onCreateNote}>
              Create note
            </Button>
            <Text fontSize="xs" color="npSubduedText" mt={2}>
              Free plan includes {noteMaximum} notes/month.
            </Text>
          </Flex>
        )}
      </ModalBody>
      <ModalFooter></ModalFooter>
    </ModalContent>
  );
};
