// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { Button } from '@chakra-ui/button';
import {
  Box,
  ButtonProps,
  CSSObject,
  forwardRef,
  IconButtonProps,
  MenuButton,
  useStyleConfig,
} from '@chakra-ui/react';

export type BlowfishButtonProps = ButtonProps & IconButtonProps;

const applyIf = (cond: boolean, obj: CSSObject) => {
  return cond ? obj : undefined;
};

export const BlowfishButton = forwardRef<BlowfishButtonProps, 'button'>(
  ({ children, ...props }, ref) => {
    const leftIconSelector = props.leftIcon
      ? props.rightIcon || props.as == MenuButton
        ? '& > span > span.chakra-button__icon:nth-of-type(1)'
        : '& > span.chakra-button__icon'
      : undefined;

    const rightIconSelector = props.rightIcon
      ? props.leftIcon
        ? '& > span > span.chakra-button__icon:nth-of-type(2)'
        : '& > span > span.chakra-button__icon:nth-of-type(1)'
      : undefined;

    const styles = useStyleConfig('Button', { size: props.size });
    const px = styles?.px ? Number(styles.px) : 4;
    const padding = styles?.px ? [0, 0, px] : [0, 0, px];

    return (
      <Button
        ref={ref}
        px={padding}
        {...props}
        sx={{
          /* fixes alignment of children
        when as={MenuButton} */
          span: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          /** left icon */
          ...applyIf(!!props.leftIcon, {
            [leftIconSelector]: {
              ml: [0, 0, 0],
              mr: [0, 0, 2],
              marginInlineEnd: 0,
            },
          }),
          /* hides right icon */
          ...applyIf(!!props.rightIcon, {
            [rightIconSelector]: {
              display: ['none', 'none', 'inline-flex'],
              ml: 2,
              mr: 0,
            },
          }),
          ...props.sx,
        }}
      >
        <Box display={['none', 'none', 'inline-flex']}>{children}</Box>
      </Button>
    );
  },
);
